<template>
  <div class="evaluate-content">
    <order-item :product="pro"/>
    <div class="grade">
      <span class="tit">描述相符</span>
      <star :commit="commit" @star="starScore" />
    </div>
    <div class="comment">
      <textarea
        class="comment-text"
        v-model="comment"
        rows="8"
        cols="20"
        placeholder="写点评价吧，你的评价对其他买家有很大的帮助"
      ></textarea>
      <div class="pic-area">
        <div
          class="preview-pic"
          v-for="(img, index) in previewPicList"
          :key="index"
        >
          <img :src="img.picPath" alt="" />
          <span class="preview-exit" @click="removePic(index)"
            ><van-icon name="cross" color="#fff" size="12"
          /></span>
        </div>
        <van-uploader :after-read="afterRead">
          <div class="camera-wrap">
            <img src="~@image/eva-camera.png" alt="" />
            <span>添加图片</span>
          </div>
        </van-uploader>
      </div>
    </div>
  </div>
</template>

<script>
import OrderItem from "./OrderItem.vue";
import Star from "@components/common/Star.vue";
import { uploadImg } from "@api/user.js";
export default {
  props : {
    pro : Object,
    commit : Boolean
  },
  data(){
    return {
      //图片列表
      previewPicList : [],
      //评论内容
      comment : "",
      //评分
      score : 0,
    }
  },
  components: {
    OrderItem,
    Star,
  },
  methods : {
    //上传图片
    afterRead(file){
      const params = new FormData();
      params.append("file", file.file);
      uploadImg(params).then(res => {
        if (res.code === 0) {
          this.previewPicList.push(res.data);
        } else {
          this.$toast(res.msg);
        }
      });
    },
    //移除图片
    removePic(i){
      this.previewPicList.splice(i,1);
    },
    //评定分数
    starScore(score){
      this.score = score;
      console.log("star:提交评分",score);
    }
  },
  watch : {
    score(){
      if(this.commit){
        let evaluateImg = [];
        if(this.previewPicList.length){
          this.previewPicList.forEach(item => {
            evaluateImg.push(item.picPath);
          });
        }
        evaluateImg = evaluateImg.length ? evaluateImg.join(",") : "";
        this.$emit("evaluCommit",{
          content : this.comment,
          evaluateImg,
          productId : this.pro.productId,
          score : this.score
        });
        this.comment = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.evaluate-content {
  padding: 0 20px 20px;
  border-top: 10px solid #eee;
  .grade {
    display: flex;
    align-items: center;
    padding: 22px 0 18px 0;
    .tit {
      margin-right: 37px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
    }
  }
  .comment {
    padding: 15px;
    background-color: #f8f8f8;
    border-radius: 5px;
    &-text::placeholder {
      font-size: 12px;
      font-weight: 500;
      color: #999999;
    }
    &-text {
      width: 100%;
      height: 80px;
      font-size: 12px;
      font-weight: 500;
      color: #999999;
      background-color: #f8f8f8;
    }
    .pic-area {
      display: flex;
      flex-wrap: wrap;
      .preview-pic {
        position: relative;
        display: flex;
        height: 100%;
        margin-right: 15px;
        margin-bottom: 15px;
        img {
          width: 80px;
          height: 80px;
        }
        .preview-exit {
          position: absolute;
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          right: 0;
          top: 0;
          width: 15px;
          height: 15px;
          border-end-start-radius: 100%;
          background-color: rgba($color: #000000, $alpha: 0.7);
        }
      }
      .camera-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 53px;
        height: 53px;
        padding-top: 12px;
        background: url("~@image/eva-dash.png") center no-repeat;
        background-size: 100% 100%;
        img {
          width: 17px;
          height: 15px;
        }
        span {
          margin-top: 8px;
          font-size: 10px;
          font-weight: 500;
          color: #4d4d4d;
        }
      }
    }
  }
}
</style>