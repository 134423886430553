<template>
  <div class="order-item">
    <div class="order-item-wrap">
      <div class="content">
        <div class="content-left">
          <div class="content-pic">
            <img :src="pic_prefix + (product.productImg ? product.productImg[0].picPath : '1626684577751_oug131415119.jpg')" alt=""/>
          </div>
          <div class="content-detail">
            <p class="content-detail-tit">{{product.productName}}</p>
            <p class="content-detail-desc">{{product.description}}</p>
            <p class="content-detail-num">x{{product.productNum}}</p>
          </div>
        </div>
        <div class="content-price">
          <span class="content-price-symbol">￥</span>
          <span class="content-price-num">{{product.price}}</span>  
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name : "order-item",
  props : {
    product : Object
  },
  data(){
    return {
      pic_prefix : "https://p.writemall.com/mallFile/product/"
    }
  }
}
</script>

<style lang="scss" scoped>
.order-item{
  font-family:PingFang SC;
  &-wrap{
    .content{
      display:flex;
      justify-content:space-between;
      flex-wrap:nowrap;
      padding:22px 0;
      border-bottom:1px solid #e3e3e3;
      &-left{
        display:flex;
      }
      &-pic{
        display:flex;
        justify-content:center;
        align-items:center;
        width: 60px;
        height: 60px;
        background: #F9F9F9;
        border-radius: 5px;
        img{
          width: 38px;
          height: 43px;
        }
      }
      &-detail{
        width:220px;
        margin-left:6px;
        &-tit{
          font-size: 15px;
          font-weight: 400;
          color: #2F3135;
        }
        &-desc,
        &-num{
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
        &-desc{
          width:210px;
          margin-top:3px;
          white-space:nowrap;
          text-overflow:ellipsis;
          overflow:hidden;
        }
        &-num{
          margin-top:5px;
        }
      }
      &-price{
        justify-items: end;
        height:25px;
        font-size: 15px;
        font-weight: bold;
        color: #2F3135;
      }
    }
  }
}
</style>