<template>
  <div class="star">
    <div class="star-item gray" v-for="(item,index) in stars" :key="'gray'+index" @click="lightStar(index)">
      <img :src="item.light?orangeImg:grayImg" alt=""/>
    </div>
  </div>
</template>

<script>
export default {
  props : ['commit'],
  data(){
    return {
      //橙色数量
      orange : 0,
      //灰色数量
      gray : 5,
      //橙img
      orangeImg : require("@image/eva-star-orange.png"),
      //灰img
      grayImg : require("@image/eva-star-gray.png"),
      stars : [
        {
          light : false
        },
        {
          light : false
        },
        {
          light : false
        },
        {
          light : false
        },
        {
          light : false
        }
      ]
    }
  },
  methods : {
    lightStar(inx){
      let num = inx + 1;
      this.orange = num;
      this.gray = 5 - this.orange;
      for(let i = 0; i < this.orange; i++){
        this.$set(this.stars[i],"light",true);
      }
      if(this.gray){
        for(let i = this.stars.length-1; i > this.orange-1; i--){
          this.$set(this.stars[i],"light",false);
        } 
      }
    }
  },
  watch : {
    commit(){
      if(this.commit){
        if(!this.orange){
          this.lightStar(4);
        };
        this.$emit("star",this.orange);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.star{
  position:relative;
  display:flex;
  align-items:center;
  height:18px;
  &-item{
    width:18px;
    height:18px;
    margin-right:15px;
    img{
      width:100%;
      height:100%;
    }
  }
}
</style>