<template>
  <div class="order-evaluate">
    <van-nav-bar
      left-arrow
      title="商品评价"
      @click-left="onLeft"
      :class="scrolling?'topfix':''"
    />
    <div class="main">
      <order-evaluate-item v-for="(pro, index) in order.childProduct" :pro="pro" :commit="commit" :key="index" @evaluCommit="evaluCommit"/>
      <div class="btn" v-if="Boolean(order.childProduct)" @click="onCommit">确定</div>
    </div>
  </div>
</template>

<script>
import OrderEvaluateItem from "./OrderEvaluateItem.vue";
import { getOrderInfo,saveEvaluate } from "@api/user.js";
import Mixin from "@mixin/mixin.js";
export default {
  data(){
    return {
      //订单数据
      order : {},
      //是否提交评论
      commit : false,
      //评论列表
      evaluList : [],
      tab:3,
    }
  },
  mixins : [Mixin],
  methods : {
    //返回
    onLeft(){
      this.$router.go(-1);
    },
    //获取订单数据
    findOrder(){
      const params = {
        orderId : this.$route.params.oid,
        payStatus : 2
      }
      getOrderInfo(JSON.stringify(params))
      .then(res => {
        if(res.code == 0){
          this.order = res.data[0];
        }
      });
    },
    //单个评论组件上交评论数据
    evaluCommit({ content,evaluateImg,score,productId }){
      let params = {
        content,
        evaluateImg,
        evaluateParentId : 0,
        orderId : this.order.orderId,
        productId,
        score
      }
      this.evaluList.push(params);
      console.log(params);
    },
    //确认评论
    onCommit(){
      this.commit = true;
    },
  },
  watch : {
    evaluList : {
      deep : true,
      handler(){
        if(this.evaluList.length == this.order.childProduct.length){
          saveEvaluate(JSON.stringify(this.evaluList))
          .then(res => {
            if(res.code == 0){
              this.$toast(res.msg);
              this.commit = false;
              this.$router.push({
                  name : "order",
                  query:{
                    tabId:2
                  }
              });
            }
          });
        }
      }
    }
  },
  components : {
    OrderEvaluateItem
  },
  created(){
    this.findOrder();
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar__content {
  height: 57px;
}
::v-deep .van-hairline--bottom::after {
  border: none;
}
::v-deep .van-icon {
  color: #333333;
}
::v-deep .van-nav-bar__text {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 3px;
}
::v-deep .topfix{
  position:fixed;
  z-index:100;
  width:100%;
  top:0;
}
::v-deep .van-uploader{
  height:53px;
  align-self:flex-end;
  margin-bottom:15px;
}
.order-evaluate{
  font-family: PingFang SC;
  .main{
    padding-bottom:50px;
    .btn{
      width:300px;
      height:57px;
      margin:20px auto 0;
      text-align:center;
      line-height:57px;
      font-size: 17px;
      font-weight: 500;
      color: #FEFEFE;
      background-color:#4587FF;
      border-radius:30px;
    }
  }
}
</style>